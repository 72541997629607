<template>

  <div class="min-h-full">
    
    <main class="py-10" v-if="message">
      <!-- Page header -->
      <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
          <div class="flex-shrink-0">
            <div class="relative">
              <div class="h-16 w-16 rounded-full bg-gray-100"></div>
              <span class="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
            </div>
          </div>
          <div>
            <h1 class="text-2xl font-bold text-gray-900">{{message.Message.subject}}</h1>
            <p class="text-sm font-medium text-gray-500">Verstuurd op <time datetime="2020-08-25">{{m(message.createdAt)}}</time></p>
          </div>
        </div>
        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <button @click="destroy" type="button" class="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">Verwijder</button>
          <!--<button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">Advance to offer</button>-->
        </div>
      </div>

      <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
          <section aria-labelledby="applicant-information-title">
            <div class="bg-white shadow sm:rounded-lg">
              <div class="px-4 py-5 sm:px-6">
                <div class="sm:col-span-2 prose" v-html="message.Message.body">                    
                </div>
              </div>
            </div>
          </section>

        </div>

      </div>
    </main>
    <main v-else>
      <div class="max-w-3xl mx-auto px-4 sm:px-6 py-20 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <span v-if="!loading">Het lijkt er op dat dit bericht niet (meer) bestaat.</span>
        <span v-else>Bericht aan 't laden.</span>
      </div>
    </main>
  </div>
</template>

<script>
import MessageService from '../../../services/admin/messages/service';
import moment from 'moment';
moment.locale('nl');

export default {
  name: 'MessageShow',
  data() {
    return {
      message: null,
      loading: true,
      currentId: this.$route.params.id
    }
  },
  methods: {
    m(d) {
      return moment(d).format('LLL');
    },
    async fetchData() {
      this.loading = true;
      return MessageService.readMessage(this.currentId).then(res => {
        if (res.status !== 200) {
          const error = new Error(res.statusText);
          throw error;
        }
        this.loading = false;
        return res.data;
      })
      .then(json => {
        // set the response data
        this.message = json;
        this.loading = false;
      })
      .catch(err => {
        this.error = err;
        // In case a custom JSON error response was provided
        if (err.json) {
          return err.json.then(json => {
            // set the JSON response message
            this.error.message = json.message;
          });
        }
      })
      .then(() => {
        this.loading = false;
      });
    
    },
    async destroy() {
      if(confirm('are you sure?')) {
        this.loading = true;
        await MessageService.deleteMessage(this.message.uuid);
        this.$router.push(`/messages/`);
        this.loading = false;
      }
    },
    // toggleRead() {
    //   this.newsItem.published = !this.newsItem.published;
    // },
  },
  mounted() {
    this.fetchData()
  },
}
</script>
